<template>
	<div class="post-thumbnail-container">
		<img
			:src="imageUrl"
			class="post-thumbnail"
			:alt="`${slug || 'default-image'}`"
		/>
		<!-- <img
			:src="imageUrl"
			class="post-thumbnail"
			:alt="`${slug || 'default-image'}`"
			@error="ImageErrorHandler"
		/> -->
	</div>
</template>

<script setup>
const config = useRuntimeConfig();

const { sourceUrl, slug } = defineProps(["sourceUrl", "slug"]);
const imageUrl = computed(() => {
	if (sourceUrl && sourceUrl.includes(config.public.hostname)) {
		return `${config.public.hostname}cdn-cgi/image/quality=100,format=webp,width=200/${sourceUrl}`;
	}

	return "/images/block-ready-bg.jpg";
});

// const ImageErrorHandler = (event) => {
// 	event.target.src = "/images/block-ready-bg.jpg";
// };
</script>
